.modal-dialog.missing_purchase_content {
  min-width: 800px;
}

.missing_purchase_content {
  background: #fff;
  padding: 20px 30px;
  margin: 90px auto 60px auto !important;
  // text-align: left;
  width: 700px !important;
  border-radius: 10px;
  box-shadow: 3px 3px 10px #000;
  min-height: 300px;
  position: relative;
  .modal-content {
    border: none !important;
  }
}

.missing_purchase_heading {
  margin: 0 -5px 30px -5px;
  background: #ec0000;
  color: #fff;
  padding: 7px 8px;
  border-radius: 5px 5px 0 0;
  box-shadow: 3px 7px 5px #ddd;
  font-size: 17px;
  text-align: center !important;
  // font-size: 2em;
  // font-family: "Roboto", "Helvetica Neue";
  // font-weight: 100;
  // background: #ec0000;

  // margin-bottom: 15px;
  // padding: 5px 5px 5px;
}


.mp-table {
  margin-bottom: 15px;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.mp-table tr:nth-child(even) {
  background: #e9e9e9;
}

.mp-table tr:nth-child(odd) {
  background: #f1f1f1;
}

.mp-table tr td:first-of-type {
  width: 170px;
  border-right: 1px dashed #aaa;
}
.mp-table td {
  vertical-align: top;
  padding: 4px 7px;
}
.mp-table,
.mp-table tr,
.mp-table td {
  border: 0;
}

td,
tr {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #000000;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee !important;
}

@media screen and (max-width: 600px) {
  .missing_purchase_content {
    width: 100% !important;
  }
}
@media screen and (max-width: 992px) {
  .missing_purchase_content {
    width: 100% !important;
  }
}

.bg-blue{
  background: #0d6efd;
}
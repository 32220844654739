.apology-screen {
  text-align: center;
  padding: 60px 0;
  background: #ffffff;
  height: 100vh;
}
.service-unavailable {
  padding: 60px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  max-width: 500px;
}

.icon {
  font-size: 100px !important;
  color: #f50505; 
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 20px;
  margin: 0;
}

.back-button{
  background-color: #f50505; 
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 25px;
  padding: auto;
}

.check-icon {
  font-size: 100px !important;
  color: #4caf50;
  margin-bottom: 20px;
}
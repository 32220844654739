
.loader-img-div {
    opacity: 1;
   
  
}

.loader-img-div {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  
  .loader-img-div > img {
    height: 50px;
  }
body {
  margin: 0;
}
.page-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  background: #fff;
  flex-direction: column;
  -o-flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
}
.wizard-heading {
  color: #000;
  text-align: center;
  font-size: 2em;
  font-family: "Roboto", "Helvetica Neue";
  font-weight: 100;
  padding-top: 50px;
  padding-bottom: 35px;
  border-bottom: 1px solid #e0e0e0;
}

// .wizard-content {
//   width: 70%;
//   padding: 35px 50px 20px 35px;
//   background-color: #fff;
//   margin-bottom: 30px;
//   border-radius: 10px;
// }

.steps-heading {
  text-align: center;
  font-size: 2em;
  font-family: "Roboto", "Helvetica Neue";
  font-weight: 100;
  margin-bottom: 15px;
  padding: 5px 5px 5px;
}

.submit-btn {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
// .submit-btn:hover {
// 	background-color: #000;
// 	border-color: #0000;
//   color: #fff;
// }

.react-dropdown-date {
  display: flex;
}

.react-dropdown-date select {
  margin-right: 10px;
}

#dropdown-date {
  display: flex;

  #dropdown-year select {
    margin-right: 20px;
    width: 85px;
  }
  #dropdown-month select {
    margin-right: 20px;
    width: 130px;
  }
  #dropdown-day select {
    margin-right: 20px;
    width: 85px;
  }
}

/* Responsive */
@media screen and (max-width: 991px) {
  // .wizard-v6-content {
  // 	width: 90%;
  // }
  .form-register .steps li a .title {
    flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
  }
  .form-register .steps li a .title .step-icon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-register .steps li a .step-text {
    padding-bottom: 20px;
    padding-left: 0;
  }
  .inner .form-row.form-row-date .form-holder select,
  .inner .form-row.form-row-date .form-holder select#year {
    width: 25%;
  }
}

@media screen and (max-width: 575px) {
  .form-register .steps {
    padding: 35px 30px 20px;
  }
  .form-register .steps li a .title .step-icon {
    padding: 20px;
    font-size: 20px;
  }
  .form-register .content {
    padding: 0 30px;
  }
  .inner .form-heading span {
    display: none;
  }
  .inner .form-images {
    display: none;
  }
  .inner .form-row {
    flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    margin: 0;
  }
  .inner .form-row .form-holder {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .inner .form-row.form-row-date .form-holder select,
  .inner .form-row.form-row-date .form-holder select#year {
    float: none;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    display: block;
  }
  .inner .form-row .form-holder .ui-datepicker-trigger {
    right: 0;
  }
  .inner .form-row .form-holder .select-btn {
    right: 2%;
  }
}

.reward-support-form-item {
  max-width: 600px;
  display: block;
  label {
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    display: block;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 4px;
    padding-bottom: 0;
  }

  input {
    padding-left: 10px !important;
    font-size: 18px;
    -webkit-appearance: none;
    border-radius: 0;
    background-color: #ffffff;
    font-family: inherit;
    border: solid 1px #333333;
    box-shadow: 0;
    color: #333333;
    display: block;
    font-size: 0.875rem;
    height: 2.3125rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.15s linear;
  }

  input:focus {
    outline: 1px solid rgba(0, 0, 0, 0);
    border: #f28d00 2px solid;
  }

  .reward-support-form-item-shopName {
    input {
      border: none;
    }

    button {
      color: black;
    }
    fieldset {
      border-color: black;
    }
    // .reward-support-form-item-shopName:focus {
    //   outline: 1px solid rgba(0, 0, 0, 0);
    //   border: #f28d00 2px solid;
    // }
  }

  .reward-support-form-item-shopName fieldset.MuiOutlinedInput-notchedOutline {
    border-color: black;
    border-width: 2px solid;
  }
  
  .reward-support-form-item-shopName .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #f28d00 ;
    border-width: 2px solid;
  }

  .reward-support-form-item-date {
    height: 50px;
    width: 600px;
  }
  .reward-support-form-item-date fieldset.MuiOutlinedInput-notchedOutline {
    border-color: black;
    border-width: 2px solid;
  }
  
  .reward-support-form-item-date .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #f28d00 ;
    border-width: 2px solid;
  }
}

.reward-support-form-item-button {
  margin-top: 30px;
  padding: 9px 33px;
  margin-bottom: 5px;
  color: #000;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
}
.reward-support-form-item-button:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.reward-support-form-page-content {
  padding: 50px 60px 60px 60px;
}

.reward-support-form-heading {
  font-size: 52px;
  line-height: 52px;
  font-family: "akzidenz-grotesk", Arial, sans-serif;
}

.gold-custom-select {
  border: 0.5px solid #000; /* Change the border color as desired */
}

.height-54px{
  height: 54px !important;
}

.gold-form-control:focus {
  outline: 1px solid rgba(0, 0, 0, 0);
  border: #f28d00 2px solid;
} 

.error-message{
  color: red;
  font-size: 14px;
}

.orderValue{
  height: 54px;
}

#email,
#orderNumber,
#orderValue,
#purchaseId,
#shopName,
#purchaseDate,
#expectedCommission,
.MuiInputBase-root,
.css-13cymwt-control,
.reward-support-form-item .reward-support-form-item-date,
input[type="file"] {
  height: 55px;
}

#email,
#orderNumber,
#orderValue,
#purchaseId,
#shopName,
#purchaseDate,
#expectedCommission,
.css-13cymwt-control,
.reward-support-form-item .reward-support-form-item-date,
input[type="file"] {
  width: 100%;
}

#overflow-hide > .MuiStack-root{
  overflow: hidden;
}

#overflow-hide input{
  padding: 0px;
}

.file-upload{
  border-radius: 5px;
  border: 0.2px solid;
}
